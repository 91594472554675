import * as React from "react";
import { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Modal from "react-modal";

import {
  Button,
  Heading,
  List,
  Page,
  PageContent,
  PageSection,
  Tile
} from "../components";
import { TestimonialCarouselCard, StyledCarousel } from "../components";

import logoHccaSrc from "../images/about-us-hcca.png";
import logoIremSrc from "../images/about-us-irem-logo.png";
import logoAmoSrc from "../images/about-us-amo-logo.png";
import logoCaiSrc from "../images/about-us-cai.png";
import logoRealtorSrc from "../images/about-us-realtor.png";
import * as CloseButton from "../images/close-icon.png";

import "./About.css";
// TODO: Copy is placeholder copy, need to add in final copy
Modal.setAppElement("#___gatsby");

interface Testimonial {
  name?: string;
  image?: string;
  company?: string;
  location?: string;
  quote?: string;
  content?: string;
}

export interface Service {
  name: string;
  html: string;
}

function parseServiceData(node) {
  return {
    name: node.name,
    html: `<h3>${node.name}</h3>${
      node.description.childContentfulRichText.html
    }`
  };
}

const AboutPage = (): JSX.Element => {
  const [modal, setValues] = useState({ isModalOpen: false, content: "" });

  const closeModal = (): void => {
    setValues({ ...modal, isModalOpen: false });
  };

  const openModal = (html): any => {
    return () => {
      setValues({ isModalOpen: true, content: html });
    };
  };

  const {
    allContentfulTestimonial,
    allContentfulGeneralEditableField,
    allContentfulAboutUsMetric,
    awardWinningServices,
    aboutUsImage,
    missionStatement,
    missionImage,
    standOutImage
  } = useStaticQuery(graphql`
    query allTestimonialAndService {
      allContentfulTestimonial {
        edges {
          node {
            name
            company
            location
            quote
            image {
              file {
                url
              }
            }
            content {
              childContentfulRichText {
                html
              }
            }
          }
        }
      }
      awardWinningServices: contentfulCollection(
        name: { eq: "About > Award Winning Services" }
      ) {
        entries {
          ... on ContentfulService {
            name
            description {
              childContentfulRichText {
                html
              }
            }
          }
        }
      }
      allContentfulGeneralEditableField {
        edges {
          node {
            title
            pageSection
            childContentfulGeneralEditableFieldContentRichTextNode {
              childContentfulRichText {
                html
              }
            }
          }
        }
      }
      allContentfulAboutUsMetric(sort: { fields: createdAt, order: ASC }) {
        edges {
          node {
            title
            content
            createdAt
          }
        }
      }
      allContentfulCollection(filter: { name: { eq: "Services" } }) {
        edges {
          node {
            name
            entries {
              ... on ContentfulService {
                name
                description {
                  childContentfulRichText {
                    html
                  }
                }
              }
            }
          }
        }
      }
      aboutUsImage: contentfulAsset(title: { eq: "About > About Us" }) {
        ...contentfulAssetFieldFragment
      }
      missionStatement: contentfulGeneralEditableField(
        title: { eq: "Hawaiiana's Mission" }
      ) {
        title
        content {
          childContentfulRichText {
            html
          }
        }
      }
      missionImage: contentfulAsset(
        title: { eq: "About > Hawaiiana's Mission" }
      ) {
        ...contentfulAssetFieldFragment
      }
      standOutImage: contentfulAsset(
        title: {
          eq: "About > Hawaiiana stands out among Association Management companies"
        }
      ) {
        ...contentfulAssetFieldFragment
      }
    }
  `);

  const testimonials = allContentfulTestimonial.edges.map(
    ({ node }): Testimonial => {
      const t = node;
      const img = t.image && t.image.file && t.image.file.url;
      const content =
        t.content &&
        t.content.childContentfulRichText &&
        t.content.childContentfulRichText.html;
      return {
        name: t.name,
        company: t.company,
        location: t.location,
        quote: t.quote,
        image: img,
        content: content
      };
    }
  );

  const aboutHawaiianaSection = allContentfulGeneralEditableField.edges.filter(
    field => field.node.pageSection === "aboutUsHeader"
  );
  const aboutStandsOutSection = allContentfulGeneralEditableField.edges.filter(
    field => field.node.pageSection === "aboutStandOut"
  );
  const aboutUsMetrics = allContentfulAboutUsMetric.edges;

  return (
    <Page layout="default" title="About">
      <Modal
        className="Modal__Content"
        isOpen={modal.isModalOpen}
        onRequestClose={closeModal}
        shouldReturnFocusAfterClose={false}
      >
        <div dangerouslySetInnerHTML={{ __html: modal.content }} />
        <button className="Modal__CloseButton" onClick={closeModal}>
          <img src={CloseButton} alt="Close" />
        </button>
      </Modal>
      <PageSection
        style={{
          background:
            "linear-gradient(0deg, rgba(255,255,255, 0) 0%, rgba(255,255,255, 1) 100%), linear-gradient(90deg, rgba(46,119,187,0.18) 0%, rgb(121, 188, 110, 0.6) 100%)"
        }}
      >
        <PageContent className="about__hero">
          <div className="featured-image">
            <img src={aboutUsImage.file.url} alt={aboutUsImage.description} />
          </div>
          <div>
            {aboutHawaiianaSection.map((content, index) => {
              return (
                <div key={`about-us-${index}`}>
                  <Heading level={1}>{content.node.title}</Heading>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        content.node
                          .childContentfulGeneralEditableFieldContentRichTextNode
                          .childContentfulRichText.html
                    }}
                    key={`about-us-header-${index}`}
                  />
                </div>
              );
            })}
          </div>
        </PageContent>

        <PageContent>
          <div className="flex-wrapper">
            <div className="tile--two-thirds">
              <div className="tile flex-wrapper about-metrics">
                {aboutUsMetrics.map((content, index) => {
                  return (
                    <div
                      className="about-metric"
                      key={`about-us-metric-${index}`}
                    >
                      <Heading level={3}>{content.node.title}</Heading>
                      <p>{content.node.content}</p>
                    </div>
                  );
                })}

                <div className="about-metric">
                  <Heading level={3}>Meet the team behind our success</Heading>
                  <Button href="/our-team">View Our Team Page</Button>
                </div>
              </div>
            </div>
          </div>
        </PageContent>
      </PageSection>
      {missionStatement && (
        <PageSection
          className="mission-section"
          style={{
            backgroundImage: `url(${missionImage.file.url})`
          }}
        >
          <PageContent>
            <Tile>
              <Heading level={2}>{missionStatement.title}</Heading>
              <div
                dangerouslySetInnerHTML={{
                  __html: missionStatement.content.childContentfulRichText.html
                }}
              />
            </Tile>
          </PageContent>
        </PageSection>
      )}

      <PageSection>
        <PageContent>
          <Tile className="services-section">
            <Heading level={2}>Our Award-Winning Services</Heading>
            <List className="about-services">
              {awardWinningServices &&
                awardWinningServices.entries.map((service, index) => {
                  try {
                    const onClickHandler = openModal(
                      parseServiceData(service).html || ""
                    );
                    return (
                      <li
                        key={`${service.name}-${index}`}
                        onClick={onClickHandler}
                      >
                        {service.name}
                      </li>
                    );
                  } catch (err) {
                    console.error(err);
                  }
                })}
            </List>
          </Tile>
        </PageContent>
      </PageSection>

      <PageSection>
        <PageContent>
          <div className="split--hero-right stand-out-section">
            <Tile>
              {aboutStandsOutSection.map((content, index) => {
                return (
                  <div key={`stands-out-${index}`}>
                    <Heading
                      level={2}
                    >{`Hawaiiana stands out among Association Management companies`}</Heading>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          content.node
                            .childContentfulGeneralEditableFieldContentRichTextNode
                            .childContentfulRichText.html
                      }}
                    />
                  </div>
                );
              })}
            </Tile>

            <div>
              <img
                className="find-your-community--sidebar-img"
                src={standOutImage.file.url}
                alt={standOutImage.description}
              />
            </div>
          </div>
        </PageContent>
      </PageSection>

      {testimonials.length > 0 && (
        <div className="tile--two-thirds">
          {testimonials.length > 0 && (
            <StyledCarousel autoplayInterval={15000}>
              {testimonials.map((t, i) => {
                return (
                  <TestimonialCarouselCard
                    key={i}
                    quote={t.quote}
                    name={t.name}
                    company={t.company}
                    location={t.location}
                    imgSrc={t.image}
                  >
                    <div dangerouslySetInnerHTML={{ __html: t.content }} />
                  </TestimonialCarouselCard>
                );
              })}
            </StyledCarousel>
          )}
        </div>
      )}
      <div className="flex-wrapper dark-gradient-section about-proposal">
        <div className="tile tile--two-thirds">
          <Heading level={3}>
            Are you interested in what Hawaiiana can provide for your property?
          </Heading>
          <div>
            <p>
              {`Submit a request to receive a personalized proposal for your property`}
            </p>
            <Button href="/request-a-proposal">Request a Proposal</Button>
          </div>
        </div>
      </div>
      <div className="flex-wrapper about-accreditations">
        <div>
          <div>
            <Heading
              level={2}
              style={{ textAlign: "center" }}
            >{`Professional Accreditations and Memberships`}</Heading>
            <p>
              {`Hawaiiana has passed the rigorous standards of accreditation by both
            the Institute of Real Estate Management and the Community
            Associations Institute. In addition, Hawaiiana is an active member
            of the Hawaii Council of Community Associations, Moiliili Resident
            Managers Association and the Honolulu Board of Realtors.`}
            </p>
          </div>
        </div>
      </div>
      <div className="about-accreditations-logos">
        <div>
          <img src={logoHccaSrc} />
        </div>
        <div>
          <img src={logoIremSrc} />
        </div>
        <div>
          <img src={logoAmoSrc} />
        </div>
        <div>
          <img src={logoCaiSrc} />
        </div>
        <div>
          <img src={logoRealtorSrc} />
        </div>
      </div>
    </Page>
  );
};

export default AboutPage;
